import { template as template_140f9d66b69e49c6868e7cf613ef4192 } from "@ember/template-compiler";
const FKText = template_140f9d66b69e49c6868e7cf613ef4192(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
