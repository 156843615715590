import { template as template_627559a91e4f4f7abb09771afe8d9d32 } from "@ember/template-compiler";
const SidebarSectionMessage = template_627559a91e4f4f7abb09771afe8d9d32(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
