import { template as template_d0a6885babfb482bbfbc4e21bf4b2f96 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d0a6885babfb482bbfbc4e21bf4b2f96(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
